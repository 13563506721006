<template>
  <div
    class="atom-navigation-bullets"
    :class="{ 'is-visible': isArrayNotEmpty(anchors) }"
  >
    <div class="atom-navigation-bullets__inner">
      <div
        v-for="anchor in anchors"
        :key="anchor.id"
        class="atom-navigation-bullets__item"
        :class="{ 'is-active': anchor.isActive }"
      >
        <div class="atom-navigation-bullets__item-line">
          <div
            class="atom-navigation-bullets__bubble"
            @click="scrollToAnchor(anchor.id)"
            @keypress="scrollToAnchor(anchor.id)"
          >
            <div class="atom-navigation-bullets__bubble-inner" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const anchorStore = useAnchorStore();
const anchors = computed(() => anchorStore.getAnchors);

const scrollToAnchor = (anchorId) => {
    anchorStore.scrollToAnchor(anchorId);
};
</script>

<style scoped lang="scss">
.atom-navigation-bullets {
    @include z-index('navigationBullets');

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 0.3s var(--ease-out--quart);

    @include mobile {
        right: 0;
        left: unset;
    }

    &.is-visible {
        transform: translateX(0);
    }
}

.atom-navigation-bullets__inner {
    display: flex;
    flex-direction: column;
    padding-bottom: 25vh;
}

.atom-navigation-bullets__item {
    display: flex;
    align-items: center;
    padding: 23px 0px;
    opacity: 0.65;
    transition: padding 0.35s var(--ease-out--quart), opacity 0.35s var(--ease-out--quart);

    @include mobile {
        flex-direction: column-reverse;
        padding: 8px 0px;
    }

    &.is-active {
        padding: 43px 0px;
        opacity: 1;

        @include mobile {
            padding: 12px 0px;
        }
    }
}

.atom-navigation-bullets__item-line {
    position: relative;
    width: 120px;
    height: 2px;
    background-color: var(--c-white);
    transition: width 0.35s var(--ease-out--quart);

    @include mobile {
        width: 14px;
        height: 1px;
    }

    .is-active & {
        width: 140px;

        @include mobile {
            width: 14px;
        }
    }
}

.atom-navigation-bullets__bubble {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--c-white);
    cursor: pointer;
    transform: translateX(50%) translateY(-50%);
    transition: width 0.35s var(--ease-out--quart), height 0.35s var(--ease-out--quart);

    &:before {
        position: absolute;
        top: -7px;
        left: -7px;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border-radius: 50%;
        background-color: rgba(182, 182, 182, 0.92);
        content: '';
        opacity: 0.2;

        @include mobile {
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
        }
    }

    .is-active & {
        width: 53px;
        height: 53px;

        @include mobile {
            width: 14px;
            height: 14px;
        }

        &:before {
            top: -14px;
            left: -14px;
            width: calc(100% + 28px);
            height: calc(100% + 28px);

            @include mobile {
                top: -4px;
                left: -4px;
                width: calc(100% + 8px);
                height: calc(100% + 8px);
            }
        }
    }

    @include mobile {
        right: unset;
        left: 0;
        width: 9px;
        height: 9px;
        transform: translateX(-50%) translateY(-50%);
    }
}
</style>
